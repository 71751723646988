<template>
  <div>
    <Pane />

    <a-card class="container">
      <div class="header">{{ detail.name }}</div>
      <div class="info">
        <span>部门：{{ department.deptUniqueName }}</span>
        <span>姓名：{{ user.userName }}</span>
        <span>得分：{{ score ? score : calculateScore() }}</span>
      </div>

      <a-form :colon="false">
        <div style="margin-bottom: 12px" v-for="(element, index) in list" :key="element.type">
          <div class="left title">
            <span style="padding-right: 4px">{{ index + 1 }}.</span>
            <DataDictFinder dictType="exam_question_type" :dictValue="element.type" />

            <span>{{ `（每题${element.score}分，共${element.total}分）` }}</span>
          </div>
          <div class="alert" v-if="element.type === 'qa'">
            <a-alert
              message="X的立方请输入 X^{3}，9的平方根请输入 \sqrt{9}，9的立方根请输入 \sqrt[3]{9}"
              type="info"
              show-icon
            />
            <Padding />
          </div>
          <div class="item" v-for="(item, idx) in element.list" :key="item.id">
            <a-form-item style="margin-bottom: 0">
              <template v-if="item.itemType === 'qa' || item.itemType === 'blank'">
                <div class="subtitle" v-if="item.itemType === 'qa'">
                  <p style="padding-right: 4px">{{ index + 1 }}.{{ idx + 1 }}.</p>

                  <span v-html="item.content"></span>
                </div>

                <div class="editor—wrapper">
                  <div class="toolbar" :id="'toolbar' + item.id">
                    <!-- 工具栏 -->
                  </div>
                  <div class="editor" :id="'editor' + item.id">
                    <!-- 编辑器 -->
                  </div>
                </div>
              </template>

              <template v-else>
                <div class="subtitle">
                  <span style="padding-right: 4px">{{ index + 1 }}.{{ idx + 1 }}.</span>
                  <span>{{ item.content }}</span>
                </div>
              </template>

              <a-textarea
                v-if="item.itemType === 'terms'"
                :auto-size="{ minRows: 3 }"
                v-model="item.userAnswer"
                :disabled="disabled"
              />

              <a-radio-group
                v-else-if="
                  item.itemType === 'judge' || item.itemType === 'radio'
                "
                v-model="item.userAnswer"
                :disabled="disabled"
              >
                <template v-if="Array.isArray(item.examItemOptionVoList)">
                  <a-radio
                    v-for="element in item.examItemOptionVoList"
                    :key="element.value"
                    :value="element.value"
                  >{{ element.value }}</a-radio>
                </template>
              </a-radio-group>

              <a-checkbox-group
                v-else-if="item.itemType === 'checkbox'"
                v-model="item.userAnswer"
                :disabled="disabled"
              >
                <template v-if="Array.isArray(item.examItemOptionVoList)">
                  <a-checkbox
                    v-for="element in item.examItemOptionVoList"
                    :key="element.value"
                    :value="element.value"
                  >{{ element.value }}</a-checkbox>
                </template>
              </a-checkbox-group>

              <div style="padding-top: 12px"></div>
            </a-form-item>
          </div>
        </div>

        <div class="center">
          <a-space>
            <a-button type="primary" @click="done" :loading="saveLoading" :disabled="disabled">交卷</a-button>
            <a-button @click="$close($route.path)">关闭</a-button>
          </a-space>
        </div>
      </a-form>

      <div class="tip" v-if="!disabled">
        <div class="tip-title">倒计时</div>
        <div class="tip-content">{{ miniteStr }}:{{ secondStr }}</div>
      </div>
    </a-card>
  </div>
</template>


<script>
import watermark from "@/mixins/watermark";
import { fetchDetail, answer, fetchAnswer, startExam } from "@/api/exam";
import { mapGetters, mapState } from "vuex";

import "@wangeditor/editor/dist/css/style.css";
import { createEditor, createToolbar } from "@wangeditor/editor";

import moment from "moment";

import request from "@/api/request";

function setStatus(params) {
  return request({
    url: "/office-service/study/user/exam/status",
    method: "post",
    params
  });
}

export default {
  name: "startExam",
  mixins: [watermark],

  data() {
    return {
      id: null,
      detail: {},
      list: [], // 题目列表

      saveLoading: false,

      score: "",
      disabled: false,

      minite: 0,
      second: 0,

      examUserId: null
    };
  },

  computed: {
    ...mapState("auth", ["user"]),
    ...mapGetters("auth", ["department"]),

    miniteStr() {
      return String(this.minite).padStart(2, "0");
    },
    secondStr() {
      return String(this.second).padStart(2, "0");
    }
  },

  activated() {
    const { query } = this.$route;
    const { id, courseId, chapterId } = query || {};

    this.courseId = courseId;
    this.chapterId = chapterId;

    if (id && this.id !== id) {
      // 初始化数据
      this.id = id;
      this.detail = {};
      this.list = [];

      this.disabled = false;
      this.score = "";
      this.minite = 0;
      this.second = 0;
      this.examUserId = null;

      this.getDetail();
    }
  },

  beforeDestroy() {
    // 销毁编辑器
    this.editorList?.forEach(item => {
      item.editor.destroy();
    });
  },

  methods: {
    getDetail() {
      const hide = this.$message.loading("加载中...", 0);
      fetchDetail({
        id: this.id
      })
        .then(res => {
          this.detail = res;
          if (Array.isArray(res.examItemList)) {
            const types = [
              ...new Set(res.examItemList.map(item => item.itemType))
            ];

            this.list = types.map(type => {
              const list = [];
              res.examItemList.forEach(item => {
                if (item.itemType === type) {
                  list.push(item);
                }
              });
              let total = 0;
              let score = 0;
              list.forEach(item => {
                total += item.score;
                score = item.score;
              });
              return {
                type,
                list,
                total,
                score
              };
            });

            const that = this;
            this.$nextTick().then(function() {
              that.initEditors(that.list);
              that.getAnswer();
            });
          }
        })
        .finally(() => {
          hide();
        });
    },

    getAnswer() {
      fetchAnswer({
        id: this.id
      }).then(res => {
        if (res && Array.isArray(res.examUserItemVoList)) {
          this.list = this.list.map(e => {
            return {
              ...e,
              list: e.list.map(item => {
                const obj = res.examUserItemVoList.find(
                  element => element.itemId === item.id
                );
                let userAnswer = obj && obj.userAnswer ? obj.userAnswer : "";

                const editorElement = this.editorList.find(
                  element => element.id === item.id
                );
                if (editorElement) {
                  editorElement.editor.setHtml(userAnswer);
                  editorElement.editor.disable();
                }

                if (
                  userAnswer.indexOf(",") > -1 &&
                  item.itemType === "checkbox"
                ) {
                  userAnswer = userAnswer.split(",");
                }
                return obj
                  ? {
                      ...item,
                      userAnswer
                    }
                  : item;
              })
            };
          });

          if (res.startTime && !res.endTime) {
            // 继续考试
            this.minite = this.detail.duration;
            this.start();
          } else {
            // 已交卷
            this.disabled = true;
            this.score = res.score;
          }
        } else {
          // 第一次来考试
          this.minite = this.detail.duration;
          this.start();
        }
      });
    },

    initEditors(list) {
      const arr = [];
      list.forEach(element => {
        element.list.forEach(item => {
          if (item.itemType === "qa" || item.itemType === "blank") {
            const editorConfig = {
              placeholder: "",
              hoverbarKeys: {
                formula: {
                  menuKeys: ["editFormula"] // “编辑公式”菜单
                }
              }
            };

            const editor = createEditor({
              selector: "#editor" + item.id,
              html: item.itemType === "blank" ? item.content : "",
              config: editorConfig,
              mode: "default" // or 'simple'
            });

            const toolbarConfig = {
              toolbarKeys: [
                "insertFormula",
                "bold",
                "underline",
                "italic",
                "fontSize"
              ]
              // insertKeys: {
              //   index: 0,
              //   keys: [
              //     "", // “插入公式”菜单
              //   ],
              // },
            };

            createToolbar({
              editor,
              selector: "#toolbar" + item.id,
              config: toolbarConfig,
              mode: "default" // or 'simple'
            });

            // editor.config.menus = [
            //   "bold",
            //   "fontSize",
            //   "italic",
            //   "underline",
            //   "strikeThrough",
            //   "indent",
            //   "lineHeight",
            //   "foreColor",
            //   "justify",
            //   "splitLine",
            //   "undo",
            //   "redo",
            // ];
            // editor.create();

            console.log(editor.getAllMenuKeys());

            arr.push({
              id: item.id,
              editor
            });
          }
        });
      });
      this.editorList = arr;
    },
    done() {
      let arr = [];
      if (Array.isArray(this.list)) {
        this.list.forEach(element => {
          element.list.forEach(item => {
            const obj = { ...item };
            if (item.itemType === "qa" || item.itemType === "blank") {
              this.editorList.forEach(element => {
                if (item.id === element.id) {
                  obj.userAnswer = element.editor.getHtml();
                }
              });
            }
            arr.push(obj);
          });
        });
      } else {
        return;
      }

      this.saveLoading = true;

      const itemList = arr.map(item => {
        return {
          itemId: item.id,
          itemType: item.itemType,
          userAnswer: Array.isArray(item.userAnswer)
            ? item.userAnswer.join(",")
            : item.userAnswer
        };
      });

      answer({
        userInfo: {
          deptId: this.department.deptId,
          deptName: this.department.deptUniqueName,
          userId: this.user.uuid,
          userName: this.user.userName,
          examId: this.detail.id,
          id: this.examUserId
        },
        itemList
      })
        .then(() => {
          if (this.chapterId) {
            this.saveLoading = true;
            setStatus({
              chapter_id: this.chapterId,
              course_id: this.courseId,
              status: 1
            })
              .then(() => {
                this.$close(this.$route.path);
              })
              .finally(() => {
                this.saveLoading = false;
              });
          } else {
            this.$close(this.$route.path);
          }
        })
        .catch(() => {
          this.saveLoading = false;
        });
    },

    // 开始考试
    start() {
      const startTime = moment().format("YYYY-MM-DD HH:mm:ss");
      startExam({
        deptId: this.department.deptId,
        deptName: this.department.deptUniqueName,
        userId: this.user.uuid,
        userName: this.user.userName,
        examId: this.detail.id,
        startTime
      }).then(res => {
        if (res) {
          this.examUserId = res.examUserId;
          // this.setTimer(res.examUserStartTime);
          this.setTimer(moment().format("YYYY-MM-DD HH:mm:ss"));
        }
      });
    },

    // 开始倒计时
    setTimer(startTime) {
      // 当前时间减去开始时间，看过去了多少分钟
      /// 考试时长减去已过去的时间，得到剩余倒计时
      console.log("start time", startTime);
      const startTemp = new Date(startTime).valueOf();
      const nowTemp = new Date().valueOf();
      const minuteDiff = parseInt((nowTemp - startTemp) / 1000 / 60);
      console.log("this.minite", this.minite, minuteDiff);
      const surplus = this.minite - minuteDiff;
      console.log("surplus", surplus);
      this.minite = surplus;

      const timer = setInterval(() => {
        this.setTime();

        if (this.second === 0 && this.minite === 0) {
          clearInterval(timer);
          this.done();
        }
      }, 1000);
      this.$once("hook:beforeDestroy", () => {
        clearInterval(timer);
      });
    },
    setTime() {
      if (this.second > 0) {
        this.second--;
      } else {
        this.minite--;
        this.second = 59;
      }
    },

    calculateScore() {
      if (!this.disabled) {
        return "--";
      }

      let sum = 0;
      this.list.forEach(item => {
        if (Array.isArray(item.list)) {
          item.list.forEach(element => {
            if (Array.isArray(element.userAnswer)) {
              if (element.userAnswer.join(",") === element.answer) {
                sum += element.score;
              }
            } else if (element.answer === element.userAnswer) {
              sum += element.score;
            }
          });
        }
      });
      return sum > 0 ? sum : "--";
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 160px;

  .header {
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 8px;
  }
  .info {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
    span {
      padding: 0 4px;
    }
  }
}

.title {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 12px;
}
.item {
  .subtitle {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.8em;
    margin-bottom: 8px;
    display: flex;
    align-items: baseline;
  }
}

.center {
  margin-top: 80px;
}

.editor—wrapper {
  border: 1px solid #e0e0e0;
  z-index: 100; /* 按需定义 */
}
.toolbar {
  border-bottom: 1px solid #e0e0e0;
  line-height: 1.5em;
}
.editor {
  height: 300px;
}

.tip {
  position: fixed;
  right: 24px;
  bottom: 24px;
  transform: translateY(-50%);
  border-radius: 50%;
  height: 80px;
  width: 80px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.3);
  background-color: #0b67bd;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .tip-title {
    font-weight: bold;
    font-size: 14px;
  }
  .tip-content {
    font-size: 18px;
  }
}
</style>

<style>
.button-container button {
  line-height: 1em !important;
}
p {
  margin-bottom: 4px;
}
table,
th,
td {
  border: 1px solid #e0e0e0;
}
th,
td {
  padding: 8px;
}
</style>